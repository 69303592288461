<template>
  <div class="title">
    <h1>{{ titData.h }}</h1>
    <span class="line"></span>
    <p v-if="titData.p">{{ titData.p }}</p>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      titData: this.data,
    };
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin: 70px auto;
  text-align: center;
  h1 {
    height: 50px;
    font-size: 30px;
    font-weight: 500;
    color: #222222;
  }
  .line {
    display: block;
    width: 60px;
    height: 4px;
    background: #4c8cf5;
    margin: auto;
  }
  p {
    font-size: 14px;
    color: #666666;
    line-height: 38px;
  }
}
</style>