<template>
  <div class="box">
    <!-- 大图部分 -->
    <div class="home_top">
      <h1>开发者中心</h1>
    </div>
    <div class="conter">
      <!-- 文档中心 -->
      <div class="doc">
        <Mytitle
          :data="{
            h: '文档中心',
          }"
        ></Mytitle>

        <ul class="doc_table">
          <li>
            <h3>新手指南</h3>
            <div class="name">
              <router-link to="/developer/navPage/sdk"
                ><span>SDK与Tools</span></router-link
              >
            </div>
            <div class="describe">
              <span
                >介绍TiSDK和贴纸Tools的用途，为您的项目带来更便捷的解决方案</span
              >
            </div>
          </li>
          <li>
            <h3>集成开发</h3>
            <div class="name">
              <router-link to="/developer/navPage/integrated"
                ><span>密钥Key</span></router-link
              >
              <router-link to="/developer/navPage/integrated/ios"
                ><span>IOS平台</span></router-link
              >
              <router-link to="/developer/navPage/integrated/android"
                ><span>Android平台</span></router-link
              >
              <router-link to="/developer/navPage/integrated/third"
                ><span>第三方集成方案</span></router-link
              >
            </div>
            <div class="describe">
              <span>介绍申请密钥Key需要的申请资料</span>
              <span>介绍IOS平台拓幻科技TiSDK集成开发步骤</span>
              <span>介绍Androis平台拓幻科技TiSDK集成开发步骤</span>
              <span>介绍第三方台拓幻科技TiSDK集成开发步骤</span>
            </div>
          </li>
          <li>
            <h3>API中心</h3>
            <div class="name">
              <router-link to="/developer/navPage/apiCenter"
                ><span>IOS接入</span></router-link
              >
              <router-link to="/developer/navPage/apiCenter/androidAccess"
                ><span>Android接入</span></router-link
              >
            </div>
            <div class="describe">
              <span>介绍IOS平台API相关参数</span>
              <span>介绍Android平台API相关参数</span>
            </div>
          </li>
          <li>
            <h3>贴纸工具</h3>
            <div class="name">
              <router-link to="/developer/navPage/sticker/tools"
                ><span>Tools使用说明</span></router-link
              >
            </div>
            <div class="describe">
              <span
                >如果您对Tools的操作和应用还不熟悉，现在您可以通过阅读此模块来得到帮助</span
              >
            </div>
          </li>
          <li>
            <h3>常见问题</h3>
            <div class="name">
              <router-link to="/developer/navPage/common"
                ><span>升级新UI</span></router-link
              >
              <router-link to="/developer/navPage/common/iosRelated"
                ><span>IOS相关</span></router-link
              >
              <router-link to="/developer/navPage/common/andRelated"
                ><span>Android相关</span></router-link
              >
              <router-link to="/developer/navPage/common/thirdParty"
                ><span>第三方集成相关</span></router-link
              >
              <router-link to="/developer/navPage/common/stickerTools"
                ><span>贴纸Tools相关</span></router-link
              >
              <router-link to="/developer/navPage/common/other"
                ><span>其他问题</span></router-link
              >
            </div>
            <div class="describe">
              <span>描述升级新UI时可能遇到的一些问题的解决办法</span>
              <span>描述IOS平台上可能遇到的一些问题的解决办法</span>
              <span>描述Android平台上可能遇到的一些问题的解决办法</span>
              <span>描述第三方平台上可能遇到的一些问题的解决办法</span>
              <span>描述配置贴纸时可能遇到的一些问题的解决办法</span>
              <span>描述其他常见问题</span>
            </div>
          </li>
          <li>
            <h3>更新说明</h3>
            <div class="name">
              <router-link to="/developer/navPage/update"
                ><span>Android端</span></router-link
              >
              <router-link to="/developer/navPage/update/iosUpdate"
                ><span>IOS端</span></router-link
              >
            </div>
            <div class="describe">
              <span>40000-latest及以上版本更新说明</span>
              <span>40000-latest及以上版本更新说明</span>
            </div>
          </li>
          <li>
            <h3>TiSDK合规使用说明</h3>
            <div class="name">
              <router-link to="/developer/navPage/compliance/guide"
                ><span>合规使用说明</span></router-link
              >
            </div>
            <div class="describe">
              <span
                >如果您对合规化使用政策说明还不熟悉，现在您可以通过阅读此模块来得到帮助</span
              >
            </div>
          </li>

        </ul>
      </div>
      <!-- 拓幻开发者 -->
      <div class="toDoc">
        <Mytitle
          :data="{
            h: '拓幻开发者中心',
          }"
        ></Mytitle>

        <div class="toDoc_conter">
          <div class="item">
            <h3>拓幻TiSDK管理平台</h3>
            <p>TiSDK产品日志</p>
            <router-link to="/developer/platform">
              <a href="#"
                >查看详情<img
                  src="@/assets/developer/icon_kaifa_xiangqing@2x.png"
                  alt=""
              /></a>
            </router-link>
            <p>【SDK名称】 TiSDK 【支持平台】 Android/iOS </p>
            <p>【开发者信息】 南京拓幻智能科技有限公司 </p>
            <p>【个人信息处理规则】 <a href="https://tillusory.com/privacy">隐私政策</a>  &nbsp;&nbsp;&nbsp;  <a href="https://tillusory.com/developer/navPage/compliance/guide">合规使用说明</a> </p>


          </div>
          <div class="item">
            <h3>拓幻贴纸配置工具</h3>
            <p>自定义贴纸配置</p>
            <a target="_blank" href="/StickerTool/index.html"
              >查看详情<img
                src="@/assets/developer/icon_kaifa_xiangqing@2x.png"
                alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mytitle from "@/components/Mytitle/index";
export default {
  components: { Mytitle },
  data() {
    return {
      // 表格数据
      /* tableData: [
        {
          title: "新手指南",
          option: [
            {
              name: "SDK与Tools",
              describe:
                "介绍TiSDK和贴纸Tools的用途，为您的项目带来更便捷的解决方案",
            },
          ],
        },
        {
          title: "集成开发",
          option: [
            {
              name: "密钥Key",
              describe: "介绍申请密钥Key需要的申请资料",
            },
            {
              name: "IOS平台",
              describe: "介绍IOS平台拓幻科技TiSDK集成开发步骤",
            },
            {
              name: "Android平台",
              describe: "介绍Androis平台拓幻科技TiSDK集成开发步骤",
            },
            {
              name: "第三方集成方案",
              describe: "介绍第三方台拓幻科技TiSDK集成开发步骤",
            },
          ],
        },
        {
          title: "API中心",
          option: [
            {
              name: "IOS接入",
              describe: "介绍IOS平台API相关参数",
            },
            {
              name: "Android接入",
              describe: "介绍Android平台API相关参数",
            },
          ],
        },
        {
          title: "贴纸工具",
          option: [
            {
              name: "Tools使用说明",
              describe:
                "如果您对Tools的操作和应用还不熟悉，现在您可以通过阅读此模块来得到帮助",
            },
          ],
        },
        {
          title: "常见问题",
          option: [
            {
              name: "升级新UI",
              describe: "描述升级新UI时可能遇到的一些问题的解决办法",
            },
            {
              name: "IOS相关",
              describe: "描述IOS平台上可能遇到的一些问题的解决办法",
            },
            {
              name: "Android相关",
              describe: "描述Android平台上可能遇到的一些问题的解决办法",
            },
            {
              name: "第三方集成相关",
              describe: "描述第三方平台上可能遇到的一些问题的解决办法",
            },
            {
              name: "贴纸Tools相关",
              describe: "描述配置贴纸时可能遇到的一些问题的解决办法",
            },
            {
              name: "其他问题",
              describe: "描述其他常见问题",
            },
          ],
        },
      ], */
    };
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
//背景大图部分
.home_top {
  width: 100%;
  height: 500px;
  line-height: 400px;
  text-align: center;
  color: #fff;
  background: url("~@/assets/developer/开发者中心首图.png") no-repeat;
  background-size: 100% 500px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  h1 {
    height: 76px;
    font-size: 44px;
    font-weight: 500;
  }
}

.doc {
  height: $width;
  margin: auto;
  background: #f5f5f5;
  padding-top: 1px;
  .doc_table {
    width: $width;
    margin: auto;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #cccccc;
      padding: 10px 0;
    }
    li:last-child {
      border-bottom: 1px solid #cccccc;
    }
    /*     li:nth-child(4) span {
      line-height: 38px;
    } */
    li:hover {
      background: #ffffff;
      box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
      .name span {
        color: #2673f1;
      }
    }

    h3 {
      width: 17.5%;
      margin-left: 61px;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
      line-height: 38px;
    }

    span {
      display: block;
      line-height: 48px;
    }
    .name {
      width: 20%;
      font-size: 16px;
      font-weight: 400;
      span {
        color: #4c8cf5;
      }
    }
    .describe {
      width: 42.8%;
      font-size: 16px;
      font-weight: 400;
      word-break: break-all;
    }
  }
}
.toDoc {
  height: 400px;
  .toDoc_conter {
    width: $width;
    margin: auto;
    display: flex;
    justify-content: space-around;
    color: #222222;
    font-weight: 400;
    font-size: 14px;
    .item {
      padding: 40px;
      width: 320px;
      height: 180px;
      border: 1px solid #cccccc;
      border-radius: 4px;
      h3 {
        font-size: 16px;
        line-height: 38px;
      }
      p {
        line-height: 38px;
      }
      a {
        color: #4c8cf5;
      }
      img {
        width: 7px;
        margin-left: 8px;
      }
    }
    .item:hover {
      border: 1px solid #4c8cf5;
      box-shadow: 0px 0px 18px 0px rgba(144, 177, 232, 0.46);
    }
  }
}
</style>